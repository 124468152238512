export type AddressValidationRequest = {
    countryCode: string;
    query: string;
};

export enum AddressValidationResultType {
    LOCALITY = 'locality',
    STREET = 'street',
    HOUSE_NUMBER = 'houseNumber',
    AREA = 'administrativeArea',
    BLOCK = 'addressBlock',
    INTERSECTION = 'intersection',
    POSTAL_CODE = 'postalCodePoint',
}

export type AddressValidationResponse = {
    title?: string;
    resultType?: AddressValidationResultType;
    HEREId?: string;
    address: {
        label?: string;
        countryCode?: string;
        countryName?: string;
        state?: string;
        stateCode?: string;
        county?: string;
        city?: string;
        district?: string;
        street?: string;
        postalCode?: string;
        houseNumber?: string;
        block?: string;
        subblock?: string;
    };
    queryLocation: {
        lat?: number;
        lng?: number;
    };
};
